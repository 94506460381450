<template>
  <div id="monthlyReportEditContainer" class="relative">
    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span>
      </li>
      <li>
        <router-link to="/mesecna-porocila">Mesečna poročila</router-link>
        <span class="vs-breadcrum--separator">/</span>
      </li>
      <li aria-current="page" class="active">
        <span v-if="monthlyReportData.id && monthlyReportData.submitted">Oddano mesečno poročilo za
          {{ moment().month(month - 1).format("MMMM") }} {{ year }} <span
            v-if="monthlyReportData.unitId && $globalFunctions.isAdmin()">({{ $globalFunctions.getUnitDataById(monthlyReportData.unitId).name + '; ' + $globalFunctions.getUnitDataById(monthlyReportData.unitId).coveredMunicipalities }})</span></span>
        <span v-if="monthlyReportData.id && !monthlyReportData.submitted">Začasno shranjeno mesečno poročilo za
          {{ moment().month(month - 1).format("MMMM") }} {{ year }} <span
            v-if="monthlyReportData.unitId && $globalFunctions.isAdmin()">({{ $globalFunctions.getUnitDataById(monthlyReportData.unitId).name + '; ' + $globalFunctions.getUnitDataById(monthlyReportData.unitId).coveredMunicipalities }})</span></span>
        <span v-if="!monthlyReportData.id">Priprava mesečnega poročila za
          {{ moment().month(month - 1).format("MMMM") }} {{ year }} <span
            v-if="monthlyReportData.unitId && $globalFunctions.isAdmin()">({{ $globalFunctions.getUnitDataById(monthlyReportData.unitId).name + '; ' + $globalFunctions.getUnitDataById(monthlyReportData.unitId).coveredMunicipalities }})</span></span>
      </li>
    </vs-breadcrumb>

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="monthlyReportData">
              <div class="vx-col w-full">
                <h1 v-if="monthlyReportData.submitted">Pregled mesečnega poročila za
                  {{ moment().month(month - 1).format("MMMM") }} {{ year }} <span
                    v-if="monthlyReportData.unitId && $globalFunctions.isAdmin()">({{ $globalFunctions.getUnitDataById(monthlyReportData.unitId).name + '; ' + $globalFunctions.getUnitDataById(monthlyReportData.unitId).coveredMunicipalities }})</span></h1>
                <h1 v-if="!monthlyReportData.submitted">Pripravi mesečno poročilo za
                  {{ moment().month(month - 1).format("MMMM") }} {{ year }} <span
                      v-if="monthlyReportData.unitId && $globalFunctions.isAdmin()">({{ $globalFunctions.getUnitDataById(monthlyReportData.unitId).name + '; ' + $globalFunctions.getUnitDataById(monthlyReportData.unitId).coveredMunicipalities }})</span></h1>
              </div>
            </div>
          </div>


          <!--          <div class="vx-card mt-5">-->
          <!--            <div class="vx-card__header">-->
          <!--              <div class="vx-card__title">-->
          <!--                <h4 class="" v-if="monthlyReportData">Statistični podatki za {{ moment().month(month-1).format("MMMM") }} {{year}} <span v-if="monthlyReportData.unit">({{monthlyReportData.unit.name}})</span> <span v-if="!monthlyReportData.unit">({{$globalFunctions.getCurrentUnit().name}})</span></h4>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="vx-card__collapsible-content">-->
          <!--              <div class="vx-card__body">-->
          <!--                {{ unitId }}-->
          <!--                {{ month }}-->
          <!--                {{ year }}-->
          <MonthlyStats v-if="monthlyReportData.id || !monthlyReportId" :unitId="unitId" :month="month" class="mt-10"
                        :year="year"></MonthlyStats>
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <vs-tabs alignment="center" class="my-10" v-model="activeTabIndex">
            <vs-tab label="1) poročanje o rednem dogajanju v enoti">
              <div class="p-5">

                <div class="vx-card mt-5" v-if="monthlyReportData.summary" style="opacity: 0.5; cursor: not-allowed;">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Povzetek dogajanja (ustaljeno dogajanje, izzivi, predlogi za rešitve,
                        posebnosti)</h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="20" v-model="monthlyReportData.summary" :disabled="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="vx-card mt-5" v-if="monthlyReportData.interestingStory" style="opacity: 0.5; cursor: not-allowed;">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Dobra zgodba</h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="20" v-model="monthlyReportData.interestingStory" :disabled="true"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-card mt-5" v-if="monthlyReportData.article" style="opacity: 0.5; cursor: not-allowed;">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Kratek sestavek za članek v občinskem glasilu</h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="20" v-model="monthlyReportData.article" :disabled="true"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-card mt-5">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Delo z uporabniki</h4>
                      <small>Ali je delo potekalo tekoče? So uporabniki izrazili zadovoljstvo s storitvijo? Če da, kdaj
                        in v kakšnem smislu? Ste imeli posebne primere? Če da, za kaj je v teh primerih šlo? Imate
                        kakšen predlog na temo izboljšanja dela z uporabniki pri Sopotnikih?</small>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="10" v-model="monthlyReportData.usersWorkReport"/>
                        </div>
                      </div>

                      <div class="flex">
                        <div class="w-full" v-if="$v.monthlyReportData.usersWorkReport.$dirty">
                          <vs-alert :active="!$v.monthlyReportData.usersWorkReport.required" :color="'danger'">
                            Polje "Delo z uporabniki" ne sme biti prazno.
                          </vs-alert>

                          <vs-alert :active="!$v.monthlyReportData.usersWorkReport.minLength" :color="'danger'">
                            Polje "Delo z uporabniki" mora vsebovati vsaj
                            {{ $v.monthlyReportData.usersWorkReport.$params.minLength.min }} znakov.
                          </vs-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-card mt-5">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Delo s prostovoljci</h4>
                      <small>Ali je delo potekalo tekoče? So prostovoljci izrazili zadovoljstvo z delom pri nas? Če da,
                        v kakšnem smislu? Ste imeli posebne primere, težave pri delu s prostovoljci? Če da, za kaj je v
                        teh primerih šlo? Je kdo od prostovoljcev izrazil, kako bi lahko nadgradili oz. izboljšali
                        storitev? Imate kakšen predlog na temo izboljšanja dela s prostovoljci pri Sopotnikih?</small>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="10" v-model="monthlyReportData.volunteerWorkReport"/>
                        </div>
                      </div>

                      <div class="flex">
                        <div class="w-full" v-if="$v.monthlyReportData.volunteerWorkReport.$dirty">
                          <vs-alert :active="!$v.monthlyReportData.volunteerWorkReport.required" :color="'danger'">
                            Polje "Delo s prostovoljci" ne sme biti prazno.
                          </vs-alert>

                          <vs-alert :active="!$v.monthlyReportData.volunteerWorkReport.minLength" :color="'danger'">
                            Polje "Delo s prostovoljci" mora vsebovati vsaj
                            {{ $v.monthlyReportData.volunteerWorkReport.$params.minLength.min }} znakov.
                          </vs-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-card mt-5">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Vpetost Sopotnikov v lokalno okolje</h4>
                      <small>Ste v lokalnem okolju zaznali kakšno organizacijo, s katero bi lahko sodelovali? Je kakšna
                        organizacija predlagala, da bi sodelovala z nami? Kdaj predlagate, da bi oddali članek za
                        občinsko glasilo? Če ste za, na katero temo nam svetujete, da ga napišemo.</small>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <div class="flex">
                        <div class="w-full">
                          <vs-textarea class="w-full" rows="10" v-model="monthlyReportData.localCommunityWorkReport"/>
                        </div>
                      </div>

                      <div class="flex">
                        <div class="w-full" v-if="$v.monthlyReportData.localCommunityWorkReport.$dirty">
                          <vs-alert :active="!$v.monthlyReportData.localCommunityWorkReport.required" :color="'danger'">
                            Polje "Vpetost Sopotnikov v lokalno okolje" ne sme biti prazno.
                          </vs-alert>

                          <vs-alert :active="!$v.monthlyReportData.localCommunityWorkReport.minLength" :color="'danger'">
                            Polje "Vpetost Sopotnikov v lokalno okolje" mora vsebovati vsaj
                            {{ $v.monthlyReportData.localCommunityWorkReport.$params.minLength.min }} znakov.
                          </vs-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-card mt-5" v-if="monthlyReportData.attachment_article_photo || monthlyReportData.attachment_article_photo_two">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h4 class="">Fotografije</h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">

                      <div class="vx-row mb-2">

                        <div class="vx-col w-1/2">
<!--                          <div>-->
<!--                            <div class="vs-input&#45;&#45;label mb-10">-->
<!--                              Fotografija 1-->
<!--                            </div>-->
<!--                            <input type="file" ref="file" @change="fileChanged"-->
<!--                                   accept="image/jpeg, image/png, image/gif" v-if="!monthlyReportData.submitted"/>-->
<!--                          </div>-->


                          <div class="photoContainer m-2 mt-10" v-if="monthlyReportData.attachment_article_photo">

                            <vs-image
                                :src="monthlyReportData.attachment_article_photo.includes('base64') ? monthlyReportData.attachment_article_photo : $globalFunctions.getImage(monthlyReportData.attachment_article_photo)"/>
                            <a class="removePhotoBtn"
                               @click="() => { monthlyReportData.attachment_article_photo = null; }"
                               v-if="!monthlyReportData.submitted">
                              <vs-icon icon="cancel" size="medium"></vs-icon>
                            </a>

                          </div>

                        </div>
                        <div class="vx-col w-1/2">
<!--                          <div>-->
<!--                            <div class="vs-input&#45;&#45;label mb-10">-->
<!--                              Fotografija 2-->
<!--                            </div>-->
<!--                            <input type="file" ref="file2" @change="fileChanged2"-->
<!--                                   accept="image/jpeg, image/png, image/gif" v-if="!monthlyReportData.submitted"/>-->
<!--                          </div>-->
                          <div class="photoContainer m-2  mt-10" v-if="monthlyReportData.attachment_article_photo_two">

                            <vs-image
                                :src="monthlyReportData.attachment_article_photo_two.includes('base64') ? monthlyReportData.attachment_article_photo_two : $globalFunctions.getImage(monthlyReportData.attachment_article_photo_two)"/>
                            <a class="removePhotoBtn"
                               @click="() => { monthlyReportData.attachment_article_photo_two = null; }"
                               v-if="!monthlyReportData.submitted">
                              <vs-icon icon="cancel" size="medium"></vs-icon>
                            </a>

                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </vs-tab>
            <vs-tab label="2) malice">
              <div class="p-5">

                <StatisticsOnDonations :unit-id="unitId" :start-date="moment().month(month - 1).year(year).startOf('month').valueOf()" :end-date="moment().month(month - 1).year(year).endOf('month').valueOf()" class="mt-5" :actionsVisible="false"></StatisticsOnDonations>

<!--                <div class="vx-card mt-5" v-if="!monthlyReportData.lunches || monthlyReportData.lunches.length === 0">-->
<!--                  <div class="vx-card__header">-->
<!--                    <div class="vx-card__title">-->
<!--                      <h4 class=""><em>Ni podatkov</em></h4>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="vx-card__collapsible-content">-->
<!--                    <div class="vx-card__body">-->
<!--                      Za {{ moment().month(month - 1).format("MMMM") }} {{ year }} (še) ni podatkov o kosilih.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="vx-card mt-5" v-for="(lunch, index) in oldLunches" :key="'lunch_' + index"
                     v-if="oldLunches && oldLunches.length > 0">
                  <div class="vx-card__header">
                    <div class="vx-card__title">
                      <h3>Kosila (star način vnosa)</h3>
                      <h4 class="">{{ index + 1 }}) {{ lunch.data[0].launch.name }}</h4>
                    </div>
                  </div>
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <table>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>datum</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(lunchItem,_index) in lunch.data">
                          <td>{{ _index + 1 }}</td>
                          <td>{{ moment(lunchItem.date).format('DD. MM. YYYY') }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td colspan="2" class="pt-5"><b>Skupno število mailc: {{ lunch.data.length }}</b></td>
                        </tr>
                        </tfoot>
                      </table>

                      <div class="mt-10">
                        <label class="vs-input--label">Komentar h koriščenju malic pri donatorju
                          {{ lunch.data[0].launch.name }}</label>
                        <vs-textarea class="w-full" rows="6" v-model="lunchNotes[lunch.lunch_id]"/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </vs-tab>
            <vs-tab label="3) gotovina">
              <div class="p-5">

                <div class="vx-card mt-5">
                  <div class="vx-card__collapsible-content">
                    <div class="vx-card__body">
                      <vs-button v-if="monthlyReportData.attachment_cashReport" color="primary" type="border"
                                 :href="getFileDownloadLink" icon="download">Prenesi datoteko s poročilom o gotovini
                      </vs-button>

                      <div v-if="financialReportsData && financialReportsData.length > 0" class="mt-10">
                        <FinancialReportsTable :financial-reports-data="financialReportsData"/>
                        <vs-row>
                          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
                            <h4 class="mt-10 mr-10 float-right"><span class="text-normal">Stanje gotovine začetek meseca:</span> <b
                                class="text-success text-bold">{{ balanceBefore }} eur</b></h4>
                          </vs-col>
                          <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
                            <h2 class="mt-10 mr-10 float-right"><span class="text-normal">Stanje gotovine konec meseca:</span> <b
                                class="text-success text-bold">{{ balanceAfter }} eur</b></h2>
                          </vs-col>
                        </vs-row>
                      </div>

                      <!--                      <div class="vs-input&#45;&#45;label mt-10 mb-5">-->
                      <!--                        Priloži excel datoteko "Gotovina"-->
                      <!--                      </div>-->
                      <!--                      <input type="file" ref="file3" @change="fileChanged3" accept=".xlsx, .xls" class=""-->
                      <!--                             v-if="!monthlyReportData.submitted"/>-->
                    </div>
                  </div>
                </div>


              </div>
            </vs-tab>
            <vs-tab label="4) odpovedani prevozi">
              <div class="p-5">
                <div class="w-full">
                  <div class="vx-card mt-5">
                    <div class="vx-card__header">
                      <div class="vx-card__title">
                        <!--                        <h4 class="">Odpovedani prevozi</h4>-->
                      </div>
                    </div>
                    <div class="vx-card__collapsible-content">
                      <div class="vx-card__body">
                        <div class="flex">
                          <div class="w-full">

                            <CanceledTransportations :monthly-report-data="monthlyReportData"
                                                     v-if="monthlyReportData.canceledTransportations && monthlyReportData.canceledTransportations.length > 0"
                                                     :editable="false"></CanceledTransportations>
                            <CanceledOrDeclinedReservations :reservations-data="canceledReservationsData"
                                                            :type="'CANCELED'"/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="5) zavrnjeni prevozi">
              <div class="p-5">
                <div class="w-full">
                  <div class="vx-card mt-5">
                    <div class="vx-card__header">
                      <div class="vx-card__title">
                        <!--                        <h4 class="">Zavrnjeni prevozi</h4>-->
                      </div>
                    </div>
                    <div class="vx-card__collapsible-content">
                      <div class="vx-card__body">
                        <div class="flex">
                          <div class="w-full">

                            <DeclinedTransportations :monthly-report-data="monthlyReportData"
                                                     v-if="monthlyReportData.declinedTransportations && monthlyReportData.declinedTransportations.length > 0"
                                                     :editable="false"></DeclinedTransportations>
                            <CanceledOrDeclinedReservations :reservations-data="declinedReservationsData"
                                                            :type="'DECLINED'"/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>


          <div class="flex mt-10 mb-20">
            <div class="w-1/2">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <vs-button type="border" icon="cancel" @click="$router.push({name: 'Mesečna poročila'})" class="mt-2"
                           v-if="!monthlyReportData.submitted">Prekliči
                </vs-button>
                <vs-button type="border" icon="arrow_back" @click="$router.push({name: 'Mesečna poročila'})" class="mt-2"
                           v-if="monthlyReportData.submitted">Nazaj
                </vs-button>

                <div v-if="!monthlyReportData.submitted" class="ml-20">
                  <vs-button size="large" color="danger" @click="beforeDeleteDailyReport" icon="delete">Izbriši
                  </vs-button>
                </div>
              </vs-col>
            </div>

            <div class="w-1/2">

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="!monthlyReportData.submitted">
                <vs-button size="large" color="primary" icon="save" class="mr-20" @click="saveMonthlyReport">Začasno
                  shrani
                </vs-button>
                <vs-button size="large" color="success" icon="send" @click="submitDailyReport">Shrani in oddaj
                </vs-button>
              </vs-col>

            </div>
          </div>


        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from '../../../helpers/DirectionsRenderer';
import vSelect from 'vue-select';
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import ReservationsList from '../../Sopotniki/reservations/ReservationsList';
import MonthlyStats from '@/components/Sopotniki/reports/MonthlyStats';
import DeclinedTransportations from "@/components/Sopotniki/reports/DeclinedTransportations";
import CanceledTransportations from "@/components/Sopotniki/reports/CanceledTransportations";
import CanceledOrDeclinedReservations from "@/components/Sopotniki/reservations/CanceledOrDeclinedReservations";
import FinancialReportsTable from "@/components/Sopotniki/donations/FinancialReportsTable";
import {
  required,
  minLength,
} from "vuelidate/lib/validators";
import StatisticsOnDonations from "@/components/Sopotniki/statistics/StatisticsOnDonations";

export default {
  name: 'reservation',

  components: {
    StatisticsOnDonations,
    CanceledTransportations,
    DeclinedTransportations,
    DirectionsRenderer,
    ReservationsList,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr,
    MonthlyStats,
    CanceledOrDeclinedReservations,
    FinancialReportsTable
  },
  props: {
    monthlyReportId: Number
  },
  data() {
    return {
      sir,
      madam,
      slSI,

      activeTabIndex: 0,

      unitId: this.$globalFunctions.getCurrentUnit().id,
      month: parseInt(this.moment().format('M')),
      year: parseInt(this.moment().format('YYYY')),

      monthlyReportData: {
        id: null,
        date: null,
        summary: null,
        interestingStory: null,
        article: null,
        attachment_article_photo: null,
        attachment_article_photo_two: null,
        attachment_cashReport: null,
        unit_id: null,
        lunches: null,
        launchNotes: null,
        canceledTransportations: null,
        declinedTransportations: null,
        submitted: null,
        usersWorkReport: null,
        volunteerWorkReport: null,
        localCommunityWorkReport: null
      },

      dailyReportsData: null,
      reservationsData: null,

      financialReportsData: null,
      balanceBefore: null,
      balanceAfter: null,

      canceledReservationsData: null,
      declinedReservationsData: null,

      lunchNotes: {},
      oldLunches: null,
    }
  },

  validations: {
    monthlyReportData: {
      usersWorkReport: {
        required,
        minLength: minLength(500)
      },
      volunteerWorkReport: {
        required,
        minLength: minLength(500)
      },
      localCommunityWorkReport: {
        required,
        minLength: minLength(500)
      }
    }
  },

  computed: {
    getFileDownloadLink() {
      return Vue.prototype.$config.api.googleDriveUrlResolve + btoa(encodeURI("https://drive.google.com/uc?id=" + this.monthlyReportData.attachment_cashReport + "&export=download"))
    }
  },

  async mounted() {
    const _this = this;

    if (_this.monthlyReportId) {
      _this.$vs.loading();
      await _this.getMonthlyReport();
      await _this.getDailyReports();
      await _this.getFinancialReports();
      await _this.getCanceledReservations();
      await _this.getDeclinedReservations();
      _this.$vs.loading.close();
      _this.getDailyReportsForOldLunches(); // deprecated
    } else {
      _this.unitId = _this.$globalFunctions.getCurrentUnit().id;
      _this.saveMonthlyReport();
    }
  },

  watch: {
    monthlyReportId: {
      async handler(val, oldVal) {
        const _this = this;
        console.log('CHANGE!!!!');
        console.log('val', val);

        if (val) {
          _this.$vs.loading();
          await _this.getMonthlyReport();
          await _this.getDailyReports();
          await _this.getFinancialReports();
          await _this.getCanceledReservations();
          await _this.getDeclinedReservations();
          _this.$vs.loading.close();
          _this.getDailyReportsForOldLunches(); // deprecated
        }
      }
    }
  },

  methods: {
    async getMonthlyReport() {
      const _this = this;

      await Vue.prototype.$http.get(`${Vue.prototype.$config.api.sopotnikiVPS}month-reports/${_this.monthlyReportId}`)
          .then((res) => {

            console.log('monthlyReport: ', res.data.data);

            _this.month = parseInt(_this.moment(res.data.data.date).format('M'));
            _this.year = parseInt(_this.moment(res.data.data.date).format('YYYY'));

            _this.monthlyReportData = res.data.data;
            _this.monthlyReportData.canceledTransportations = JSON.parse(res.data.data.canceledTransportations);
            _this.monthlyReportData.declinedTransportations = JSON.parse(res.data.data.declinedTransportations);
            _this.unitId = res.data.data.unitId;

            let lunchNotes = JSON.parse(res.data.data.launchNotes);
            let lunchNotesItems = {};
            _.each(lunchNotes, function (value, key) {
              lunchNotesItems[value.lunchId] = value.comment ? value.comment : '';
            });
            _this.lunchNotes = lunchNotesItems;


            _this.$forceUpdate();
            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log('error', error);
            _this.$vs.loading.close();
            return false
          });
    },

    async saveMonthlyReport() {
      const _this = this;

      _this.$vs.loading();

      console.log(" _this.monthlyReportData", _this.monthlyReportData);

      let formattedLunches = [];
      _.each(_this.lunchNotes, function (value, key) {
        formattedLunches.push({lunchId: key, comment: value})
      });

      let data = {
        id: _this.monthlyReportData.id,
        date: _this.monthlyReportData.date ? _this.moment(_this.monthlyReportData.date).valueOf() : _this.moment().valueOf(),
        unitId: _this.monthlyReportData.unitId ? _this.monthlyReportData.unitId : _this.$globalFunctions.getCurrentUnit().id,
        summary: _this.monthlyReportData.summary,
        interestingStory: _this.monthlyReportData.interestingStory,
        article: _this.monthlyReportData.article,
        attachment_article_photo: _this.monthlyReportData.attachment_article_photo,
        attachment_article_photo_two: _this.monthlyReportData.attachment_article_photo_two,
        attachment_cashReport: _this.monthlyReportData.attachment_cashReport,
        unit_id: _this.monthlyReportData.unit_id,
        lunches: JSON.stringify(formattedLunches),
        canceledTransportations: JSON.stringify(_this.monthlyReportData.canceledTransportations),
        declinedTransportations: JSON.stringify(_this.monthlyReportData.declinedTransportations),
        submitted: _this.monthlyReportData.submitted,
        usersWorkReport: _this.monthlyReportData.usersWorkReport,
        volunteerWorkReport: _this.monthlyReportData.volunteerWorkReport,
        localCommunityWorkReport: _this.monthlyReportData.localCommunityWorkReport
      };

      // let formData = new FormData();
      // formData.append("id", _this.monthlyReportData.id);
      // formData.append("date", _this.monthlyReportData.date);
      // formData.append("summary", _this.monthlyReportData.summary);
      // formData.append("interestingStory", _this.monthlyReportData.interestingStory);
      // formData.append("article", _this.monthlyReportData.article);
      // formData.append("attachment_article_photo", _this.$refs.file && _this.$refs.file.files[0] ? _this.$refs.file.files[0] : _this.monthlyReportData.attachment_article_photo);
      // formData.append("attachment_article_photo_two", _this.$refs.file2 && _this.$refs.file2.files[0] ? _this.$refs.file2.files[0] : _this.monthlyReportData.attachment_article_photo_two);
      // formData.append("attachment_cashReport", _this.$refs.file3 && _this.$refs.file3.files[0] ? _this.$refs.file3.files[0] : _this.monthlyReportData.attachment_cashReport);
      // formData.append("unit_id", _this.monthlyReportData.unit_id);
      // formData.append("lunches", JSON.stringify(formattedLunches));
      // formData.append("canceledTransportations", JSON.stringify(_this.monthlyReportData.canceledTransportations));
      // formData.append("declinedTransportations", JSON.stringify(_this.monthlyReportData.declinedTransportations));
      // formData.append("submitted", _this.monthlyReportData.submitted);
      // formData.append("usersWorkReport", _this.monthlyReportData.usersWorkReport);
      // formData.append("volunteerWorkReport", _this.monthlyReportData.volunteerWorkReport);
      // formData.append("localCommunityWorkReport", _this.monthlyReportData.localCommunityWorkReport);
      //
      // console.log("formData", formData);

      if(_this.monthlyReportData.id) {
        await _this.updateMonthlyReport(data);
      } else {
        await _this.createMonthlyReport(data);
      }

    },

    async updateMonthlyReport(data) {
      const _this = this;

      await Vue.prototype.$http.put(`${Vue.prototype.$config.api.sopotnikiVPS}month-reports/${_this.monthlyReportData.id}` , data)
        .then((res) => {


          console.log('Month report saved: ', res.data.data);
          _this.$vs.notify({
            color: 'success',
            title: 'Mesečno poročilo uspešno posodobljeno!',
            text: '',
            position: 'top-right'
          });
          _this.$vs.loading.close();
          // _this.$router.go(-1);
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });
    },

    async createMonthlyReport(data) {
      const _this = this;

      await Vue.prototype.$http.post(`${Vue.prototype.$config.api.sopotnikiVPS}month-reports`, data)
        .then((res) => {


          console.log('Month report saved: ', res.data.data);
          _this.$vs.notify({
            color: 'success',
            title: 'Mesečno poročilo uspešno ustvarjeno!',
            text: '',
            position: 'top-right'
          });
          _this.$vs.loading.close();
          _this.monthlyReportData.id = res.data.data.id;
          _this.$router.push({name: 'MonthlyReportEdit', params: {monthlyReportId: res.data.data.id}});
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });

    },

    submitDailyReport() {
      const _this = this;


      Vue.$confirm({
        title: 'Ali ste prepričani, da želite oddati mesečno poročilo za ' + _this.moment().month(_this.month - 1).format("MMMM") + ' ' + _this.year + '?',
        message: 'Po potrditvi mesečnega poročila ne bo več mogoče urejati.',
        button: {
          yes: 'Oddaj',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {

            this.$v.$touch();
            this.$nextTick(() => this.focusFirstStatus());
            if (this.$v.$invalid) {
              console.log("this.$v.", this.$v);
              return;
            } else {
              _this.monthlyReportData.submitted = true;
              _this.saveMonthlyReport();
            }
          }
        }
      });


    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert').getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop - 200, 'smooth'
      );
    },

    beforeDeleteDailyReport() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati mesečno poročilo za  ' + _this.moment(_this.monthlyReportData.date).format('MMMM YYYY') + '?',
        message: 'Po potrditvi bo mesečno poročilo trajno izbrisano.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteDailyReport();
          }
        }
      });
    },

    async deleteDailyReport() {
      const _this = this;

      _this.$vs.loading();

      await Vue.prototype.$http.delete(`${Vue.prototype.$config.api.sopotnikiVPS}month-reports/${_this.monthlyReportData.id}`)
          .then((res) => {

            console.log('monthReport deleted');
            _this.$vs.loading.close();
            _this.$router.push({name: 'Mesečna poročila'});

          })
          .catch((error) => {
            console.log('error', error);
            _this.$vs.loading.close();
            return false
          });

    },

    customDateFormatter(date) {
      console.log("date", date);
      return this.moment(date).format('DD. MM. YYYY');
    },

    onDateChange() {
      const _this = this;
      // _this.$vs.loading();
      //
      // _this.getVolunteers().then(() => {
      //   _this.getMonthlyReports().then(() => {
      //     _this.$vs.loading.close();
      //   });
      // });

    },

    async getDailyReportsForOldLunches() {
      const _this = this;

      const url = `${Vue.prototype.$config.api.productionOld}dailyReports/unit/${_this.unitId}/${_this.year}/${_this.month}/1`;

      await Vue.prototype.$http.get(url)
          .then((res) => {

            let lunches = _.filter(res.data.data, function (item) {
              if (item.launch_id != null && item.launch.name !== 'brez kosila' && item.launch.name !== 'Kosilo - 3,79 eur') {
                return item
              }
            });

            lunches = _.sortBy(lunches, ['date'], ['asc']);

            _this.oldLunches = _.chain(lunches)
                .groupBy('launch_id')
                .map((value, key) => ({lunch_id: key, data: value}))
                .value();
          })
          .catch((error) => {
            console.log('error', error);
            _this.$vs.loading.close();
            return false
          });
    },

    async getDailyReports() {
      const _this = this;

      _this.$vs.loading();


      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      Vue.prototype.$http.get(url)
        .then((res) => {
          console.log("_this.dailyReportsData", res.data);
          const dailyReportsData = _.sortBy(res.data.data, ['date']);


          _this.lunchDonorData = _.chain(dailyReportsData)
            .groupBy('lunchDonor.id')
            .map((value, key) => {
              return {donor: key, donorData: value[0] ? value[0].lunchDonor : {}, data: value}
            })
            .filter(el => el.donor !== 'undefined')
            .value();
          console.log("_this.lunchDonorData", _this.lunchDonorData);
          //_this.lunchDonorData = _.filter(_this.lunchDonorData, el => el.donor !== 'undefined');

          _this.breakfastDonorData = _.chain(dailyReportsData)
            .groupBy('breakfastDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].breakfastDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();

          _this.drinkDonorData = _.chain(dailyReportsData)
            .groupBy('drinkDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].drinkDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();

          _this.otherDonationDonorData = _.chain(dailyReportsData)
            .groupBy('otherDonationDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].otherDonationDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },


    async getFinancialReports() {
      const _this = this;
      const year = _this.moment(_this.monthlyReportData.date).format('YYYY');

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting', {
        params: {
          unit: _this.unitId === -1 ? null : _this.unitId,
          startDate: _this.moment().year(year).month(_this.month - 1).startOf('month').valueOf(),
          endDate: _this.moment().year(year).month(_this.month - 1).endOf('month').valueOf(),
        }
      })
          .then((res) => {
            _this.financialReportsData = res.data.data.financial_reports;
            _this.balanceBefore = Math.round(res.data.data.balance_before * 100) / 100;
            _this.balanceAfter = Math.round(res.data.data.balance_after * 100) / 100;
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
    },

    async getCanceledReservations() {
      const _this = this;

      console.log("_this.month", _this.month);
      console.log("_this.monthlyReportData", _this.moment(_this.monthlyReportData.date).format('YYYY'));
      const year = _this.moment(_this.monthlyReportData.date).format('YYYY');

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations', {
        params: {
          unit: _this.unitId === -1 ? null : _this.unitId,
          startDate: _this.moment().year(year).month(_this.month - 1).startOf('month').valueOf(),
          endDate: _this.moment().year(year).month(_this.month - 1).endOf('month').valueOf(),
          "type": "CANCELED",
        }
      })
          .then((res) => {
            _this.canceledReservationsData = res.data.data;
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
    },

    async getDeclinedReservations() {
      const _this = this;
      const year = _this.moment(_this.monthlyReportData.date).format('YYYY');

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations', {
        params: {
          unit: _this.unitId === -1 ? null : _this.unitId,
          startDate: _this.moment().year(year).month(_this.month - 1).startOf('month').valueOf(),
          endDate: _this.moment().year(year).month(_this.month - 1).endOf('month').valueOf(),
          "type": "DECLINED",
        }
      })
          .then((res) => {
            _this.declinedReservationsData = res.data.data;
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
    },

    fileChanged() {
      const _this = this;

      let file = _this.$refs.file;
      console.log(file.files[0]);

      if (file.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.monthlyReportData.attachment_article_photo = e.target.result;
        }
        reader.readAsDataURL(file.files[0]);
      }
    },


    fileChanged2() {
      const _this = this;

      let file = _this.$refs.file2;
      console.log(file.files[0]);

      if (file.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.monthlyReportData.attachment_article_photo_two = e.target.result;
        }
        reader.readAsDataURL(file.files[0]);
      }
    },

    fileChanged3() {
      const _this = this;

      let file = _this.$refs.file3;
      this.monthlyReportData.attachment_cashReport = file;
    }


  }
}
</script>


<template>
  <div id="monthlyStats">

    <vs-row
        vs-align="flex-start"
        vs-type="flex" vs-justify="center" vs-w="12">

      <vs-col vs-align="flex-start"
              vs-type="flex" vs-justify="center" vs-w="3" vs-sm="12" vs-md="6">
        <div class="w-full m-2">


          <div class="vx-card" style="min-height: 455px">
            <div class="vx-card__header">
              <div class="vx-card__title text-center w-full d-block">
                <h1 v-if="statisticReservationsData">{{
                    statisticReservationsData.departureTotal
                  }}</h1>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body text-center">
                <h4>prevozov</h4>

                <div v-for="reservationPurposeItem in reservationPurposeData" :key="reservationPurposeData.label"
                     v-if="reservationPurposeData && statisticReservationsData" class="mt-2">

                  <vs-row
                      vs-align="flex-start"
                      vs-type="flex" vs-justify="flex-start" vs-w="12" class="mt-10" style="justify-content: center; align-items: center; align-self: center;">
                    <vs-col vs-align="flex-start"
                            vs-type="flex" vs-justify="flex-start" vs-w="3" vs-sm="12" vs-md="3">
                      <vs-avatar size="large" :icon="reservationPurposeItem.icon" class="mr-5"/>
                    </vs-col>
                    <vs-col vs-align="flex-start"
                            vs-type="flex" vs-justify="flex-start" vs-w="6" vs-sm="12" vs-md="6" class="text-left">
                      <h5>{{ reservationPurposeItem.label }}</h5>
                    </vs-col>
                    <vs-col vs-align="flex-end"
                            vs-type="flex" vs-justify="flex-end" vs-w="3" vs-sm="12" vs-md="3" class="text-right">
                      <h5>
                      {{ reservationPurposeItem.count }} <br/><small>{{
                        (parseInt(reservationPurposeItem.count) / parseInt(statisticReservationsData.departureTotal) * 100).toFixed(2)
                      }}
                        %</small>
                      </h5>
                    </vs-col>
                  </vs-row>


<!--                  <div class="row mb-lg">-->
<!--                    <div class="col-md-5 col-md-offset-1 text-left">-->
<!--                      <vs-avatar size="large" :icon="reservationPurposeItem.icon" class="mr-5"/>-->
<!--                      <small>{{ reservationPurposeItem.count }}</small>-->
<!--                    </div>-->
<!--                    <div class="col-md-2 text-right"><b>{{ reservationPurposeItem.label }}</b></div>-->
<!--                    <div class="col-md-4 text-right">-->
<!--                      <div class="mr-lg">-->
<!--                        <small>-->
<!--                          <small>-->
<!--                            ({{-->
<!--                              (parseInt(reservationPurposeItem.count) / parseInt(statisticReservationsData.departureTotal) * 100).toFixed(2)-->
<!--                            }}-->
<!--                            %)-->
<!--                          </small>-->
<!--                        </small>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>


        </div>
      </vs-col>

      <vs-col vs-align="flex-start"
              vs-type="flex" vs-justify="flex-start" vs-w="9" vs-sm="12" vs-md="6" style="flex-direction: column;">

        <vs-row
            vs-direction="column"
            vs-align="flex-start"
            vs-type="flex" vs-justify="flex-start" vs-w="12">

          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6" class="h-full">
            <div class="w-full m-2 h-full">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticReservationsData">
                    <h1 class="text-center">{{ statisticReservationsData.unique_customers }}</h1>

                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">
                    <h4 class="">število različinih uporabnikov</h4>
                  </div>
                </div>
              </div>
            </div>

          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticCustomersData">
                    <h1>{{ statisticCustomersData.allCustomersCount }}</h1>

                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">
                    <h4 class="">skupno število
                      uporabnikov</h4>
                  </div>
                </div>
              </div>
            </div>

          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticReservationsData">
                    <div v-for="(item, index) in statisticReservationsData.route_distance_total_by_car"
                         :key="'distance_reservations_car_' + item.car_id" class="mb-3">
                      <span v-if="item.route_distance_total > 0">
                        <h1>{{ item.route_distance_total }} km</h1>
                      <small>{{
                          $globalFunctions.getCarByIdFromStore(item.car_id) ? $globalFunctions.getCarByIdFromStore(item.car_id).name : 'ni podatka'
                        }}</small>
                      </span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">

                    <h4 class="">kilometrov
                      po poteh</h4>
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticsDailyReportData">
                    <div v-for="(item, index) in statisticsDailyReportData.odometer_by_car"
                         :key="'distance_daily_reports_car_' + item.car_id" class="mb-3">
                      <span v-if="item.odometer_total > 0">
                        <h1>{{ item.odometer_total }} km</h1>
                      <small>{{
                          $globalFunctions.getCarByIdFromStore(item.car_id) ? $globalFunctions.getCarByIdFromStore(item.car_id).name : 'ni podatka'
                        }}</small>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">
                    <h4 class="">kilometrov
                      po števcu</h4>


                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
            vs-direction="column"
            vs-align="flex-start"
            vs-type="flex" vs-justify="flex-start" vs-w="12">


          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticsDailyReportData">
                    <h1>{{ statisticsDailyReportData.volunteer_hours_total }}</h1>

                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">
                    <h4 class="">število
                      prostovoljskih ur</h4>
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block">
                    <h1>{{ uniqueVolunteers }}</h1>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">

                    <h4 class="">število aktivnih
                      prostovoljcev v mesecu</h4>
                  </div>
                </div>
              </div>
            </div>

          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" vs-sm="12" vs-md="6">
            <div class="w-full m-2">
              <div class="vx-card" style="min-height: 220px">
                <div class="vx-card__header">
                  <div class="vx-card__title text-center w-full d-block" v-if="statisticVolunteersData">
                    <h1>{{ statisticVolunteersData.volunteers_count }}</h1>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="vx-card__collapsible-content">
                  <div class="vx-card__body text-center">
                    <h4 class="">skupno število
                      prostovoljcev</h4>

                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>


  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from "vue-select";

export default {
  name: 'MonthlyStats',

  components: {
    'v-select': vSelect,
  },
  props: {
    unitId: Number,
    month: Number,
    year: Number,
  },
  data() {
    return {
      dailyReportsData: null,
      reservationsData: null,
      statisticsDailyReportData: null,
      statisticReservationsData: null,
      statisticCustomersData: null,
      statisticVolunteersData: null,

      reservationPurposeData: null,

      uniqueVolunteers: 0
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p0 = _this.$store.dispatch("setAllCars");
    let p1 = _this.getDailyReports();
    let p2 = _this.getReservations();
    let p3 = _this.getStatisticsDailyReport();
    let p4 = _this.getStatisticReservations();
    let p5 = _this.getStatisticCustomers();
    let p6 = _this.getStatisticVolunteers();

    Promise.all([p0, p1, p2, p3, p4, p5, p6]).then(() => {
      _this.cars = _this.$store.getters.getAllCars;
      _this.$vs.loading.close();
    });

  },

  watch: {},

  methods: {
    async getDailyReports() {
      const _this = this;

      const url = `${Vue.prototype.$config.api.productionOld}dailyReports/unit/${_this.unitId}/${_this.year}/${_this.month}/1`;

      await Vue.prototype.$http.get(url)
          .then((res) => {
            _this.dailyReportsData = res.data.data;
            console.log("_this.dailyReportsData", _this.dailyReportsData);

            let volunteerIds = []
            _.each(res.data.data, function (key) {
              volunteerIds.push(key.volunteers_id);
            });

            this.uniqueVolunteers = _.uniq(volunteerIds).length;

          })
          .catch((error) => {
            console.log('error', error);
            _this.$vs.loading.close();
            return false
          });
    },

    async getReservations() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'dashboard/' + _this.unitId + '/' + _this.year + '/' + _this.month)
          .then((res) => {
            _this.reservationsData = res.data.data;
            console.log("_this.reservationsData", _this.reservationsData);

            _this.reservationPurposeData = [];

            let results = _.countBy(res.data.data, 'reservation_purpose');
            console.log("results", results);

            _.each(results, function (key, value) {
              _this.reservationPurposeData.push({
                reservation_purpose: value,
                count: key,
                label: _this.$globalFunctions.getReservationPurposeLabel(parseInt(value)),
                icon: _this.$globalFunctions.getReservationPurposeIcon(parseInt(value))
              });
            });

            _this.reservationPurposeData = _.sortBy(_this.reservationPurposeData, function (count) {
              return count;
            });

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
    },

    async getStatisticsDailyReport() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/daily_report/' + _this.unitId + '/' + _this.year + '/' + (parseInt(_this.month) - 1))
          .then((res) => {
            _this.statisticsDailyReportData = res.data.data;
          });
    },

    async getStatisticReservations() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/reservations/' + _this.unitId + '/' + _this.year + '/' + (parseInt(_this.month) - 1))
          .then((res) => {
            _this.statisticReservationsData = res.data.data;

            let departureTotal = 0;
            for (let i = 0; i < res.data.data.departure.length; i++) {
              departureTotal += res.data.data.departure[i].count;
            }
            _this.statisticReservationsData.departureTotal = departureTotal;
          });
    },

    async getStatisticCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/customers/' + _this.unitId + '/' + _this.year + '/' + (parseInt(_this.month) - 1))
          .then((res) => {
            _this.statisticCustomersData = res.data.data;

            let allCustomersCount = 0;
            _.each(res.data.data, function (val) {
              allCustomersCount += val.count;
            })

            _this.statisticCustomersData.allCustomersCount = allCustomersCount;

          });
    },

    async getStatisticVolunteers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/volunteers/' + _this.unitId + '/' + _this.year + '/' + (parseInt(_this.month) - 1))
          .then((res) => {
            _this.statisticVolunteersData = res.data.data;
          });
    },


  }
}
</script>


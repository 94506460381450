<template>
  <div id="reservationContainer">
    <MonthlyReportEdit :monthlyReportId="monthlyReportId"></MonthlyReportEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import MonthlyReportEdit from "../../components/Sopotniki/reports/MonthlyReportEdit";


  export default {
    name: 'MonthlyReport',

    components: {
      MonthlyReportEdit
    },

    data() {
      return {
        monthlyReportId: this.$route.params.monthlyReportId ? parseInt(this.$route.params.monthlyReportId) : null,
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      // _this.getReservation();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.dailyReportId = null;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      // getReservation() {
      //   const _this = this;
      //
      //   _this.$vs.loading();
      // },

    }
  }
</script>


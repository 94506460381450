<template>
  <div id="canceledTransportations">

    <div class="mb-10">

      <div class="grid grid-cols-1">

        <table class="vs-table vs-table--tbody-table">
          <thead class="vs-table--thead">
          <tr>
            <th style="width: 40px;" class="text-center">#</th>
            <th style="width: 130px">Datum</th>
            <th style="width: 200px">Potnik</th>
            <th>Razlog</th>
            <th v-if="editable">Akcije</th>
          </tr>
          </thead>
          <tbody class="vs-table--tbody">
          <tr v-if="!monthlyReportData.canceledTransportations || monthlyReportData.canceledTransportations.length == 0">
            <td class="text-center" colspan="5">

              <vs-alert color="primary" icon-pack="feather" icon="icon-info" class="mt-10">
                <span>Ni vnosov</span>
              </vs-alert>

            </td>
          </tr>
          <tr v-for="(canceledTransportationItem,index) in monthlyReportData.canceledTransportations" v-if="monthlyReportData.canceledTransportations && monthlyReportData.canceledTransportations.length > 0"
              :key="'canceledTransportationItem' + index" class="tr-values vs-table--tr tr-table-state-null">
            <td class="text-center">{{ index + 1 }}</td>
            <td>{{ moment(canceledTransportationItem.date).format('DD. MM. YYYY') }}</td>
            <td>{{ canceledTransportationItem.customer }}</td>
            <td>{{ canceledTransportationItem.reason }}</td>
            <td v-if="editable">
              <div class="vs-col vs-xs- vs-sm- vs-lg-"
                   style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                <vs-button size="small" color="primary" type="border" icon="edit"
                           @click="editCanceledTransportation(canceledTransportationItem, index)"
                           class="my-5 mx-2">Uredi
                </vs-button>
                <vs-button size="small" color="danger" type="border" icon="remove"
                           @click="deleteCanceledTransportation(canceledTransportationItem, index)"
                           class="my-5 mx-2">Izbriši
                </vs-button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <vs-button size="small" color="success" type="border" icon="add" @click="showAddCanceledTransportationModal()" class="" v-if="editable">
      Dodaj
      odpovedan prevoz
    </vs-button>


    <vs-popup :title="currentTransportationId !== null ? 'Urejanje odpovedanega prevoza' : 'Dodajanje odpovedanega prevoza'"
              :active.sync="editModal">
      <div class="grid grid-cols-1">
        <div>
          <label class="vs-input--label">Datum odpovedanega prevoza</label>
          <datepicker placeholder="Datum odpovedanega prevoza" :language="slSI" :format="customDateFormatter" :monday-first="true"
                      v-model="currentTransportationDate"></datepicker>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-input class="w-full" label="Uporabnik" type="text"
                    v-model="currentTransportationCustomer"/>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <label class="vs-input--label">Razlog</label>
          <vs-textarea class="w-full" type="text"
                       v-model="currentTransportationReason"/>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-button size="medium" color="success" icon="check" @click="addCanceledTransportation()">
            <span v-if="currentTransportationId !== null">Shrani spremembe</span>
            <span v-if="currentTransportationId === null">Dodaj odpovedan prevoz</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from "vue-select";
import generateUniqueId from "generate-unique-id";
import {slSI} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  name: 'canceledTransportations',

  components: {
    'v-select': vSelect,
    'datepicker': Datepicker,
  },
  props: {
    monthlyReportData: Object,
    editable: Boolean
  },
  data() {
    return {
      editModal: false,

      currentTransportationIdx: null,
      currentTransportationId: null,
      currentTransportationCustomer: null,
      currentTransportationDate: null,
      currentTransportationReason: null,

      slSI: slSI,
    }
  },

  computed: {},

  mounted() {
    const _this = this;
  },

  watch: {},

  methods: {
    addCanceledTransportation() {
      const _this = this;
      if (_this.currentTransportationId) {
        _this.monthlyReportData.canceledTransportations[_this.currentTransportationIdx] = {
          id: _this.currentTransportationId,
          customer: _this.currentTransportationCustomer,
          date: _this.currentTransportationDate,
          reason: _this.currentTransportationReason
        };
      } else {
        _this.monthlyReportData.canceledTransportations.push({
          id: generateUniqueId(),
          customer: _this.currentTransportationCustomer,
          date: _this.currentTransportationDate,
          reason: _this.currentTransportationReason
        });
      }

      _this.editModal = false;
    },

    showAddCanceledTransportationModal() {
      const _this = this;

      _this.currentTransportationId = null;
      _this.currentTransportationIdx = null;
      _this.currentTransportationCustomer = null;
      _this.currentTransportationDate = new Date();
      _this.currentTransportationReason = null;

      _this.editModal = true;
    },

    editCanceledTransportation(transportation, idx) {
      const _this = this;

      _this.currentTransportationId = transportation.id;
      _this.currentTransportationIdx = idx;
      _this.currentTransportationCustomer = transportation.customer;
      _this.currentTransportationDate = transportation.date;
      _this.currentTransportationReason = transportation.reason;

      _this.editModal = true;
    },
    deleteCanceledTransportation(transportation, idx) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati odpovedan prevoz za uporabnika: ' + transportation.customer + '?',
        message: 'Po potrditvi bo vnos izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.monthlyReportData.canceledTransportations.splice(idx, 1);
          }
        }
      });
    },
    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },
  }
}
</script>

